import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  IconButton,
  Box
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Garden } from '../../types/garden';
import { useAuth } from '../../hooks/useAuth';
import { gardenService } from '../../services/gardenService';
import { useNavigate } from 'react-router-dom';

interface GardenListProps {
  gardens: Garden[];
  onUpdate: () => void;
}

export default function GardenList({ gardens, onUpdate }: GardenListProps) {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleDelete = async (gardenId: string) => {
    if (!window.confirm('Are you sure you want to delete this garden?')) return;
    
    try {
      await gardenService.deleteGarden(user!.uid, gardenId);
      onUpdate();
    } catch (error) {
      console.error('Error deleting garden:', error);
    }
  };

  return (
    <>
      {gardens.map((garden) => (
        <Grid item xs={12} sm={6} md={4} key={garden.id}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                {garden.name}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Location: {garden.location}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Size: {garden.size} sq ft
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {garden.notes}
              </Typography>
            </CardContent>
            <CardActions>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => navigate(`/garden/${garden.id}`)}
                >
                  View Details
                </Button>
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`/garden/${garden.id}/edit`)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => handleDelete(garden.id!)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </>
  );
}
