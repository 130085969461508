import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Grid,
  Button,
  CircularProgress,
  Paper
} from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { gardenService } from '../../services/gardenService';
import { Garden, Plant } from '../../types/garden';
import PlantList from './PlantList';
import AddPlantDialog from './AddPlantDialog';

export default function GardenDetail() {
  const { gardenId } = useParams<{ gardenId: string }>();
  const { user } = useAuth();
  const navigate = useNavigate();
  
  const [garden, setGarden] = useState<Garden | null>(null);
  const [plants, setPlants] = useState<Plant[]>([]);
  const [loading, setLoading] = useState(true);
  const [openAddPlant, setOpenAddPlant] = useState(false);

  const loadGardenData = useCallback(async () => {
    if (!user || !gardenId) return;
    try {
      const gardenData = await gardenService.getGarden(user.uid, gardenId);
      if (!gardenData) {
        navigate('/dashboard');
        return;
      }
      setGarden(gardenData);
      const plantsData = await gardenService.getPlants(user.uid, gardenId);
      setPlants(plantsData);
    } catch (error) {
      console.error('Error loading garden:', error);
      navigate('/dashboard');
    } finally {
      setLoading(false);
    }
  }, [user, gardenId, navigate]);

  useEffect(() => {
    loadGardenData();
  }, [loadGardenData]);

  const handleAddPlant = async (plant: Omit<Plant, 'id'>) => {
    if (!user || !gardenId) return;
    try {
      await gardenService.addPlant(user.uid, gardenId, plant);
      loadGardenData();
      setOpenAddPlant(false);
    } catch (error) {
      console.error('Error adding plant:', error);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!garden) {
    return null;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Box>
          <Typography variant="h4" component="h1" gutterBottom>
            {garden.name}
          </Typography>
          <Typography color="text.secondary" gutterBottom>
            Location: {garden.location} | Size: {garden.size} sq ft
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenAddPlant(true)}
        >
          Add New Plant
        </Button>
      </Box>

      {plants.length === 0 ? (
        <Paper sx={{ p: 4, textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            No Plants Yet
          </Typography>
          <Typography color="text.secondary" paragraph>
            Start adding plants to your garden to track their growth and care needs.
          </Typography>
        </Paper>
      ) : (
        <Grid container spacing={3}>
          <PlantList
            plants={plants}
            gardenId={gardenId!}
            onUpdate={loadGardenData}
          />
        </Grid>
      )}

      <AddPlantDialog
        open={openAddPlant}
        onClose={() => setOpenAddPlant(false)}
        onAdd={handleAddPlant}
      />
    </Container>
  );
}
