import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Alert
} from '@mui/material';
import { membershipService } from '../../services/membership';
import { MembershipPlan, UserSubscription } from '../../types/membership';
import { auth } from '../../config/firebase';

export function Checkout(): JSX.Element {
  const { planId } = useParams<{ planId: string }>();
  const navigate = useNavigate();
  const [plan, setPlan] = useState<MembershipPlan | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/signin');
      return;
    }

    if (planId) {
      const selectedPlan = membershipService.getMembershipPlan(planId as any);
      if (!selectedPlan) {
        setError('Invalid plan selected');
        return;
      }
      setPlan(selectedPlan);
    }
  }, [planId, navigate]);

  const handleSubscribe = async () => {
    if (!plan || !auth.currentUser) return;

    setLoading(true);
    setError(null);

    try {
      // Create a new subscription
      const subscription: UserSubscription = {
        userId: auth.currentUser.uid,
        tier: plan.id,
        status: 'active',
        currentPeriodStart: new Date(),
        currentPeriodEnd: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // 30 days
        cancelAtPeriodEnd: false
      };

      await membershipService.createSubscription(subscription);
      navigate('/dashboard');
    } catch (err: any) {
      setError(err.message || 'Failed to process subscription');
    } finally {
      setLoading(false);
    }
  };

  if (!plan) {
    return (
      <Container maxWidth="sm" sx={{ py: 8 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Loading...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ py: 8 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Checkout
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            {plan.name} Plan
          </Typography>
          <Typography variant="h6" color="primary" gutterBottom>
            ${plan.price}/month
          </Typography>
          <Typography variant="body1" color="text.secondary">
            You will be charged ${plan.price} monthly for access to:
          </Typography>
          <ul>
            <li>
              <Typography>
                {plan.features.maxPlants === -1 ? 'Unlimited' : plan.features.maxPlants} Plants
              </Typography>
            </li>
            {plan.features.customNotifications && (
              <li>
                <Typography>Custom Notifications</Typography>
              </li>
            )}
            {plan.features.expertSupport && (
              <li>
                <Typography>Expert Support</Typography>
              </li>
            )}
            {plan.features.aiAssistant && (
              <li>
                <Typography>AI Assistant</Typography>
              </li>
            )}
          </ul>
        </Box>

        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={handleSubscribe}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : `Subscribe Now - $${plan.price}/month`}
        </Button>
      </Paper>
    </Container>
  );
}
