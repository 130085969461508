import {
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  serverTimestamp
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../config/firebase';
import { Garden, Plant, CareHistoryEntry } from '../types/garden';

export const gardenService = {
  // Gardens
  async createGarden(userId: string, garden: Omit<Garden, 'id' | 'createdAt'>) {
    const gardensRef = collection(db, `users/${userId}/gardens`);
    const newGarden = {
      ...garden,
      createdAt: serverTimestamp()
    };
    return addDoc(gardensRef, newGarden);
  },

  async getGarden(userId: string, gardenId: string) {
    const gardenRef = doc(db, `users/${userId}/gardens/${gardenId}`);
    const gardenSnap = await getDoc(gardenRef);
    if (!gardenSnap.exists()) return null;
    return {
      id: gardenSnap.id,
      ...gardenSnap.data()
    } as Garden;
  },

  async getGardens(userId: string) {
    const gardensRef = collection(db, `users/${userId}/gardens`);
    const q = query(gardensRef, orderBy('createdAt', 'desc'));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Garden[];
  },

  async updateGarden(userId: string, gardenId: string, garden: Partial<Garden>) {
    const gardenRef = doc(db, `users/${userId}/gardens/${gardenId}`);
    return updateDoc(gardenRef, garden);
  },

  async deleteGarden(userId: string, gardenId: string) {
    const gardenRef = doc(db, `users/${userId}/gardens/${gardenId}`);
    return deleteDoc(gardenRef);
  },

  // Plants
  async addPlant(userId: string, gardenId: string, plant: Omit<Plant, 'id'>) {
    const plantsRef = collection(db, `users/${userId}/gardens/${gardenId}/plants`);
    return addDoc(plantsRef, {
      ...plant,
      createdAt: serverTimestamp()
    });
  },

  async getPlants(userId: string, gardenId: string) {
    const plantsRef = collection(db, `users/${userId}/gardens/${gardenId}/plants`);
    const q = query(plantsRef, orderBy('createdAt', 'desc'));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Plant[];
  },

  async updatePlant(
    userId: string,
    gardenId: string,
    plantId: string,
    plant: Partial<Plant>
  ) {
    const plantRef = doc(db, `users/${userId}/gardens/${gardenId}/plants/${plantId}`);
    return updateDoc(plantRef, plant);
  },

  async deletePlant(userId: string, gardenId: string, plantId: string) {
    const plantRef = doc(db, `users/${userId}/gardens/${gardenId}/plants/${plantId}`);
    return deleteDoc(plantRef);
  },

  // Care History
  async addCareHistoryEntry(
    userId: string,
    gardenId: string,
    plantId: string,
    entry: Omit<CareHistoryEntry, 'id'>
  ) {
    const historyRef = collection(
      db,
      `users/${userId}/gardens/${gardenId}/plants/${plantId}/careHistory`
    );
    return addDoc(historyRef, {
      ...entry,
      createdAt: serverTimestamp()
    });
  },

  async getCareHistory(userId: string, gardenId: string, plantId: string) {
    const historyRef = collection(
      db,
      `users/${userId}/gardens/${gardenId}/plants/${plantId}/careHistory`
    );
    const q = query(historyRef, orderBy('createdAt', 'desc'));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as CareHistoryEntry[];
  },

  // Image Upload
  async uploadPlantImage(userId: string, gardenId: string, plantId: string, file: File) {
    const path = `plants/${userId}/${gardenId}/${plantId}/${file.name}`;
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  }
};
