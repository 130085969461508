import { 
    collection, 
    doc, 
    getDoc, 
    setDoc, 
    updateDoc,
    query,
    where,
    getDocs,
    serverTimestamp
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { 
    MembershipTier, 
    UserProfile, 
    UserPrivateData, 
    UserSubscription,
    MembershipPlan 
} from '../types/membership';

export const MEMBERSHIP_PLANS: Record<MembershipTier, MembershipPlan> = {
    free: {
        id: 'free',
        name: 'Free',
        price: 0,
        billingPeriod: 'monthly',
        features: {
            maxPlants: 5,
            customNotifications: false,
            expertSupport: false,
            aiAssistant: false,
            weatherAlerts: true,
            communityAccess: true,
            prioritySupport: false
        }
    },
    tier1: {
        id: 'tier1',
        name: 'Basic',
        price: 4.99,
        billingPeriod: 'monthly',
        features: {
            maxPlants: 15,
            customNotifications: true,
            expertSupport: false,
            aiAssistant: false,
            weatherAlerts: true,
            communityAccess: true,
            prioritySupport: false
        }
    },
    tier2: {
        id: 'tier2',
        name: 'Pro',
        price: 9.99,
        billingPeriod: 'monthly',
        features: {
            maxPlants: 50,
            customNotifications: true,
            expertSupport: true,
            aiAssistant: false,
            weatherAlerts: true,
            communityAccess: true,
            prioritySupport: true
        }
    },
    tier3: {
        id: 'tier3',
        name: 'Expert',
        price: 19.99,
        billingPeriod: 'monthly',
        features: {
            maxPlants: -1, // unlimited
            customNotifications: true,
            expertSupport: true,
            aiAssistant: true,
            weatherAlerts: true,
            communityAccess: true,
            prioritySupport: true
        }
    }
};

class MembershipService {
    private readonly usersCollection = collection(db, 'users');
    private readonly userPrivateCollection = collection(db, 'userPrivate');
    private readonly subscriptionsCollection = collection(db, 'subscriptions');

    async createUserProfile(uid: string, data: Partial<UserProfile>): Promise<void> {
        const userRef = doc(this.usersCollection, uid);
        const profile: UserProfile = {
            uid,
            email: data.email || '',
            displayName: data.displayName || '',
            photoURL: data.photoURL,
            createdAt: new Date(),
            lastLogin: new Date(),
            membershipTier: 'free'
        };
        await setDoc(userRef, profile);
    }

    async createPrivateUserData(uid: string, data: Partial<UserPrivateData>): Promise<void> {
        const userPrivateRef = doc(this.userPrivateCollection, uid);
        const privateData: UserPrivateData = {
            uid,
            phone: data.phone,
            address: data.address,
            preferences: data.preferences || {
                emailNotifications: true,
                pushNotifications: true,
                smsNotifications: false
            }
        };
        await setDoc(userPrivateRef, privateData);
    }

    async getUserProfile(uid: string): Promise<UserProfile | null> {
        const userRef = doc(this.usersCollection, uid);
        const userSnap = await getDoc(userRef);
        return userSnap.exists() ? userSnap.data() as UserProfile : null;
    }

    async getUserPrivateData(uid: string): Promise<UserPrivateData | null> {
        const userPrivateRef = doc(this.userPrivateCollection, uid);
        const userPrivateSnap = await getDoc(userPrivateRef);
        return userPrivateSnap.exists() ? userPrivateSnap.data() as UserPrivateData : null;
    }

    async updateUserProfile(uid: string, data: Partial<UserProfile>): Promise<void> {
        const userRef = doc(this.usersCollection, uid);
        await updateDoc(userRef, {
            ...data,
            lastLogin: serverTimestamp()
        });
    }

    async updateUserPrivateData(uid: string, data: Partial<UserPrivateData>): Promise<void> {
        const userPrivateRef = doc(this.userPrivateCollection, uid);
        await updateDoc(userPrivateRef, data);
    }

    async createSubscription(subscription: UserSubscription): Promise<void> {
        const subscriptionRef = doc(this.subscriptionsCollection, subscription.userId);
        await setDoc(subscriptionRef, subscription);
        
        // Update user's membership tier
        await this.updateUserProfile(subscription.userId, {
            membershipTier: subscription.tier
        });
    }

    async getSubscription(uid: string): Promise<UserSubscription | null> {
        const subscriptionRef = doc(this.subscriptionsCollection, uid);
        const subscriptionSnap = await getDoc(subscriptionRef);
        return subscriptionSnap.exists() ? subscriptionSnap.data() as UserSubscription : null;
    }

    async cancelSubscription(uid: string): Promise<void> {
        const subscriptionRef = doc(this.subscriptionsCollection, uid);
        await updateDoc(subscriptionRef, {
            status: 'cancelled',
            cancelAtPeriodEnd: true
        });
    }

    getMembershipPlan(tier: MembershipTier): MembershipPlan {
        return MEMBERSHIP_PLANS[tier];
    }

    getAllMembershipPlans(): MembershipPlan[] {
        return Object.values(MEMBERSHIP_PLANS);
    }

    async getUsersByMembershipTier(tier: MembershipTier): Promise<UserProfile[]> {
        const q = query(this.usersCollection, where('membershipTier', '==', tier));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => doc.data() as UserProfile);
    }
}

export const membershipService = new MembershipService();
