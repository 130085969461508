export function NotFound(): JSX.Element {
    return (
        <div>
            <h2>404 - Page Not Found</h2>

            <div>
                <p>
                    If this error persists, please contact the web admin:
                </p>
                <a href="mailto:chrislnk12@gmail.com">chrislnk12@gmail.com</a>
            </div>
        </div>
    );
}
