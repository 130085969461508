import { ThemeProvider, createTheme } from '@mui/material';
import { PropsWithChildren } from 'react';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2ecc71',
      dark: '#27ae60',
    },
    secondary: {
      main: '#3498db',
    },
    text: {
      primary: '#2c3e50',
      secondary: '#7f8c8d',
    },
  },
  typography: {
    fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
        },
      },
    },
  },
});

export function Theme({ children }: PropsWithChildren): JSX.Element {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}
