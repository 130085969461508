import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  Button,
  CircularProgress
} from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { gardenService } from '../../services/gardenService';
import { Garden } from '../../types/garden';
import GardenList from './GardenList';
import AddGardenDialog from './AddGardenDialog';

export function Dashboard() {
  const { user } = useAuth();
  const [gardens, setGardens] = useState<Garden[]>([]);
  const [loading, setLoading] = useState(true);
  const [openAddGarden, setOpenAddGarden] = useState(false);

  const loadGardens = useCallback(async () => {
    if (!user) return;
    try {
      const userGardens = await gardenService.getGardens(user.uid);
      setGardens(userGardens);
    } catch (error) {
      console.error('Error loading gardens:', error);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    loadGardens();
  }, [loadGardens]);

  const handleAddGarden = async (garden: Omit<Garden, 'id' | 'createdAt'>) => {
    try {
      await gardenService.createGarden(user!.uid, garden);
      loadGardens();
      setOpenAddGarden(false);
    } catch (error) {
      console.error('Error adding garden:', error);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          My Gardens
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenAddGarden(true)}
        >
          Add New Garden
        </Button>
      </Box>

      {gardens.length === 0 ? (
        <Paper sx={{ p: 4, textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            Welcome to Your Garden Dashboard!
          </Typography>
          <Typography color="text.secondary" paragraph>
            You haven't created any gardens yet. Click the button above to get started.
          </Typography>
        </Paper>
      ) : (
        <Grid container spacing={3}>
          <GardenList gardens={gardens} onUpdate={loadGardens} />
        </Grid>
      )}

      <AddGardenDialog
        open={openAddGarden}
        onClose={() => setOpenAddGarden(false)}
        onAdd={handleAddGarden}
      />
    </Container>
  );
}
