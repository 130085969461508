import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Container,
  Link,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';

const Header: React.FC = () => {
  const theme = useTheme();
  const user = true; // assuming user is logged in, replace with actual logic

  return (
    <AppBar 
      position="static" 
      color="transparent" 
      elevation={0}
      sx={{ 
        borderBottom: `1px solid ${theme.palette.divider}`,
        bgcolor: 'background.paper',
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ py: 1 }}>
          <Typography
            variant="h6"
            component={RouterLink}
            to="/"
            sx={{
              fontWeight: 700,
              color: 'primary.main',
              textDecoration: 'none',
              flexGrow: 1,
            }}
          >
            GrassRoots Care
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button
              component={RouterLink}
              to="/"
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Home
            </Button>
            <Button
              component={RouterLink}
              to="/features"
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Features
            </Button>
            {user && (
              <Button
                component={RouterLink}
                to="/dashboard"
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                My Garden
              </Button>
            )}
          </Box>

          <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
            <Link
              component={RouterLink}
              to="/features"
              color="text.primary"
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              Features
            </Link>
            <Button
              component={RouterLink}
              to="/signin"
              color="primary"
              variant="outlined"
              size="large"
              sx={{ ml: 2 }}
            >
              Sign In
            </Button>
            <Button
              component={RouterLink}
              to="/signup"
              color="primary"
              variant="contained"
              size="large"
            >
              Get Started
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
