import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../config/firebase';
import { membershipService } from '../services/membership';
import { MembershipTier, UserSubscription } from '../types/membership';

interface MembershipContextType {
  currentTier: MembershipTier;
  subscription: UserSubscription | null;
  loading: boolean;
  error: string | null;
  refreshMembership: () => Promise<void>;
}

const MembershipContext = createContext<MembershipContextType>({
  currentTier: 'free',
  subscription: null,
  loading: true,
  error: null,
  refreshMembership: async () => {},
});

export function MembershipProvider({ children }: { children: React.ReactNode }) {
  const [currentTier, setCurrentTier] = useState<MembershipTier>('free');
  const [subscription, setSubscription] = useState<UserSubscription | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const refreshMembership = async () => {
    if (!auth.currentUser) {
      setCurrentTier('free');
      setSubscription(null);
      setLoading(false);
      return;
    }

    try {
      const sub = await membershipService.getSubscription(auth.currentUser.uid);
      if (sub && sub.status === 'active') {
        setCurrentTier(sub.tier);
        setSubscription(sub);
      } else {
        setCurrentTier('free');
        setSubscription(null);
      }
    } catch (err: any) {
      setError(err.message);
      setCurrentTier('free');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        refreshMembership();
      } else {
        setCurrentTier('free');
        setSubscription(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <MembershipContext.Provider
      value={{
        currentTier,
        subscription,
        loading,
        error,
        refreshMembership,
      }}
    >
      {children}
    </MembershipContext.Provider>
  );
}

export function useMembership() {
  const context = useContext(MembershipContext);
  if (context === undefined) {
    throw new Error('useMembership must be used within a MembershipProvider');
  }
  return context;
}
