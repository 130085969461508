import { Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { Features } from './pages/Features';
import { Dashboard } from './pages/Dashboard';
import GardenDetail from './pages/Garden';
import SignUp from './components/auth/SignUp';
import SignIn from './components/auth/SignIn';
import { Checkout } from './pages/Checkout';
import { NotFound } from './errors/404';
import PrivateRoute from './components/auth/PrivateRoute';

export function Router(): JSX.Element {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/features" element={<Features />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/checkout/:planId" element={<Checkout />} />
            <Route path="/garden/:gardenId" element={<PrivateRoute><GardenDetail /></PrivateRoute>} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}
