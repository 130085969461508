import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box
} from '@mui/material';
import { Garden } from '../../types/garden';

interface AddGardenDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (garden: Omit<Garden, 'id' | 'createdAt'>) => void;
}

export default function AddGardenDialog({ open, onClose, onAdd }: AddGardenDialogProps) {
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    size: '',
    notes: ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onAdd({
      name: formData.name,
      location: formData.location,
      size: Number(formData.size),
      notes: formData.notes
    });
    setFormData({ name: '', location: '', size: '', notes: '' });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add New Garden</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <TextField
              label="Garden Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              fullWidth
            />
            <TextField
              label="Location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              required
              fullWidth
            />
            <TextField
              label="Size (sq ft)"
              name="size"
              type="number"
              value={formData.size}
              onChange={handleChange}
              required
              fullWidth
            />
            <TextField
              label="Notes"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              multiline
              rows={4}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            Add Garden
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
