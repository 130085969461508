import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { Plant } from '../../types/garden';

interface AddPlantDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (plant: Omit<Plant, 'id'>) => void;
}

export default function AddPlantDialog({ open, onClose, onAdd }: AddPlantDialogProps) {
  const [formData, setFormData] = useState({
    name: '',
    species: '',
    notes: '',
    status: 'healthy'
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const now = new Date();
    const nextWatering = new Date();
    nextWatering.setDate(now.getDate() + 7); // Default to 7 days

    onAdd({
      name: formData.name,
      species: formData.species,
      plantedDate: now,
      lastWatered: now,
      nextWateringDue: nextWatering,
      notes: formData.notes,
      status: formData.status as Plant['status'],
      images: []
    });
    
    setFormData({
      name: '',
      species: '',
      notes: '',
      status: 'healthy'
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add New Plant</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <TextField
              label="Plant Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              fullWidth
            />
            <TextField
              label="Species"
              name="species"
              value={formData.species}
              onChange={handleChange}
              required
              fullWidth
            />
            <FormControl fullWidth required>
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={formData.status}
                label="Status"
                onChange={(e) => setFormData(prev => ({ ...prev, status: e.target.value }))}
              >
                <MenuItem value="healthy">Healthy</MenuItem>
                <MenuItem value="needs-attention">Needs Attention</MenuItem>
                <MenuItem value="diseased">Diseased</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Notes"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              multiline
              rows={4}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            Add Plant
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
