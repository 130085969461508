import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import NotificationsIcon from '@mui/icons-material/Notifications';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import TimelineIcon from '@mui/icons-material/Timeline';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import BugReportIcon from '@mui/icons-material/BugReport';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

export function Features(): JSX.Element {
  const theme = useTheme();

  const mainFeatures = [
    {
      title: 'Plant Identification',
      description: 'Instantly identify any plant or grass with our advanced AI technology.',
      icon: <LocalFloristIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      details: [
        'High-accuracy plant recognition',
        'Detailed species information',
        'Common and scientific names',
        'Native habitat information'
      ]
    },
    {
      title: 'Care Instructions',
      description: 'Get personalized care guides for every plant in your garden.',
      icon: <WaterDropIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      details: [
        'Watering schedules',
        'Sunlight requirements',
        'Soil preferences',
        'Seasonal care tips'
      ]
    },
    {
      title: 'Smart Reminders',
      description: 'Never forget to care for your plants with intelligent notifications.',
      icon: <NotificationsIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      details: [
        'Customizable reminders',
        'Weather-based adjustments',
        'Seasonal care alerts',
        'Growth stage tracking'
      ]
    },
    {
      title: 'Weather Integration',
      description: 'Care recommendations that adapt to your local weather conditions.',
      icon: <WbSunnyIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      details: [
        'Real-time weather data',
        'Automatic care adjustments',
        'Frost and heat warnings',
        'Rainfall tracking'
      ]
    }
  ];

  const additionalFeatures = [
    {
      title: 'Growth Analytics',
      description: 'Track your garden\'s progress with detailed analytics and insights.',
      icon: <TimelineIcon />,
    },
    {
      title: 'Plant Database',
      description: 'Access our extensive database of plants, flowers, and gardening tips.',
      icon: <LocalLibraryIcon />,
    },
    {
      title: 'Pest Control',
      description: 'Identify and treat common garden pests and diseases.',
      icon: <BugReportIcon />,
    },
    {
      title: 'Biodiversity',
      description: 'Promote local biodiversity with native plant recommendations.',
      icon: <EmojiNatureIcon />,
    },
  ];

  return (
    <Box>
      {/* Hero Section */}
      <Box 
        sx={{
          bgcolor: 'background.paper',
          pt: { xs: 8, md: 12 },
          pb: { xs: 8, md: 12 },
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                component="h1"
                variant="h2"
                gutterBottom
                sx={{ 
                  fontWeight: 800,
                  fontSize: { xs: '2.5rem', md: '3.75rem' },
                  lineHeight: 1.2,
                }}
              >
                Powerful Features for Your Garden
              </Typography>
              <Typography
                variant="h5"
                color="text.secondary"
                paragraph
                sx={{ mb: 4, maxWidth: 480 }}
              >
                Everything you need to create and maintain a thriving garden, powered by advanced technology.
              </Typography>
              <Button
                component={RouterLink}
                to="/signup"
                variant="contained"
                color="primary"
                size="large"
              >
                Start Growing Today
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src="/images/features-hero.jpg"
                alt="Garden features"
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Main Features Section */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={4}>
          {mainFeatures.map((feature, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card 
                elevation={0} 
                sx={{ 
                  height: '100%',
                  bgcolor: 'background.paper',
                  border: `1px solid ${theme.palette.divider}`,
                  '&:hover': {
                    borderColor: 'primary.main',
                    boxShadow: 1,
                  },
                }}
              >
                <CardContent sx={{ p: 4 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    {feature.icon}
                    <Typography variant="h5" component="h3" sx={{ ml: 2, fontWeight: 600 }}>
                      {feature.title}
                    </Typography>
                  </Box>
                  <Typography variant="body1" color="text.secondary" paragraph>
                    {feature.description}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <List dense>
                    {feature.details.map((detail, detailIndex) => (
                      <ListItem key={detailIndex} disableGutters>
                        <ListItemIcon sx={{ minWidth: 36 }}>
                          <CheckCircleOutlineIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={detail} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Additional Features Section */}
      <Box sx={{ bgcolor: 'background.paper', py: 8, borderTop: `1px solid ${theme.palette.divider}` }}>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{ 
              mb: 6,
              fontWeight: 700,
              fontSize: { xs: '2rem', md: '2.5rem' },
            }}
          >
            And Much More
          </Typography>
          <Grid container spacing={4}>
            {additionalFeatures.map((feature, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box
                  sx={{
                    textAlign: 'center',
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: 'inline-flex',
                      p: 2,
                      borderRadius: '50%',
                      bgcolor: 'primary.light',
                      color: 'primary.main',
                      mb: 2,
                    }}
                  >
                    {feature.icon}
                  </Box>
                  <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {feature.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* CTA Section */}
      <Box 
        sx={{ 
          bgcolor: 'primary.main',
          color: 'primary.contrastText',
          py: 8,
          textAlign: 'center',
        }}
      >
        <Container maxWidth="sm">
          <Typography
            variant="h4"
            gutterBottom
            sx={{ 
              fontWeight: 700,
              mb: 2,
            }}
          >
            Ready to Transform Your Garden?
          </Typography>
          <Typography variant="h6" sx={{ mb: 4, opacity: 0.9 }}>
            Join thousands of happy gardeners using GrassRoots Care
          </Typography>
          <Button
            component={RouterLink}
            to="/signup"
            variant="contained"
            size="large"
            sx={{
              bgcolor: 'common.white',
              color: 'primary.main',
              '&:hover': {
                bgcolor: 'grey.100',
              },
            }}
          >
            Get Started Free
          </Button>
        </Container>
      </Box>
    </Box>
  );
};
