import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  IconButton,
  Box,
  Chip
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  WaterDrop,
} from '@mui/icons-material';
import { Plant } from '../../types/garden';
import { useAuth } from '../../hooks/useAuth';
import { gardenService } from '../../services/gardenService';

interface PlantListProps {
  plants: Plant[];
  gardenId: string;
  onUpdate: () => void;
}

export default function PlantList({ plants, gardenId, onUpdate }: PlantListProps) {
  const { user } = useAuth();

  const handleDelete = async (plantId: string) => {
    if (!window.confirm('Are you sure you want to delete this plant?')) return;
    
    try {
      await gardenService.deletePlant(user!.uid, gardenId, plantId);
      onUpdate();
    } catch (error) {
      console.error('Error deleting plant:', error);
    }
  };

  const handleWater = async (plantId: string) => {
    try {
      const now = new Date();
      const nextWatering = new Date();
      nextWatering.setDate(now.getDate() + 7); // Default to 7 days

      await gardenService.updatePlant(user!.uid, gardenId, plantId, {
        lastWatered: now,
        nextWateringDue: nextWatering
      });

      await gardenService.addCareHistoryEntry(user!.uid, gardenId, plantId, {
        date: now,
        action: 'watering',
        notes: 'Plant watered'
      });

      onUpdate();
    } catch (error) {
      console.error('Error updating plant:', error);
    }
  };

  const getStatusColor = (status: Plant['status']) => {
    switch (status) {
      case 'healthy':
        return 'success';
      case 'needs-attention':
        return 'warning';
      case 'diseased':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <>
      {plants.map((plant) => (
        <Grid item xs={12} sm={6} md={4} key={plant.id}>
          <Card>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography variant="h6" component="h2">
                  {plant.name}
                </Typography>
                <Chip
                  label={plant.status}
                  color={getStatusColor(plant.status)}
                  size="small"
                />
              </Box>
              <Typography color="text.secondary" gutterBottom>
                Species: {plant.species}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Last Watered: {plant.lastWatered.toLocaleDateString()}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Next Watering: {plant.nextWateringDue.toLocaleDateString()}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {plant.notes}
              </Typography>
            </CardContent>
            <CardActions>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button
                  size="small"
                  startIcon={<WaterDrop />}
                  onClick={() => handleWater(plant.id!)}
                >
                  Water
                </Button>
                <Box>
                  <IconButton size="small">
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => handleDelete(plant.id!)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </>
  );
}
