import React from 'react';
import { Box } from '@mui/material';
import Header from './components/layout/Header';
import { Router } from './Router';
import { MembershipProvider } from './contexts/MembershipContext';

export function App() {
  return (
    <MembershipProvider>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Header />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Router />
        </Box>
      </Box>
    </MembershipProvider>
  );
}

export default App;
